import * as React from "react";
import { Box } from "@mui/material";
import amazonImage from "./../assets/amazon.png";
import deezerImage from "./../assets/deezer.png";
import itunesImage from "./../assets/itunes.png";
import spotifyImage from "./../assets/spotify.png";
import youtubeImage from "./../assets/youtube.png";

const styles = {
  icon: {
    width: "40px",
    objectFit: "contain",
    height: "40px",
    filter: "sepia(0%)",
  },
};

export default function Stream() {
  const streamingPlatforms = [
    {
      title: "amazon-music",
      link: "https://www.amazon.com/music/player/artists/B0BRK12DCX/zohar-river",
      icon: amazonImage,
    },
    {
      title: "deezer",
      link: "https://www.deezer.com/en/artist/195685927",
      icon: deezerImage,
    },
    {
      title: "apple-music",
      link: "https://music.apple.com/us/artist/zohar-river/1662387672",
      icon: itunesImage,
    },
    {
      title: "spotify",
      link: "https://open.spotify.com/artist/2MRVRiJOnRj7ThHNHBFDcL",
      icon: spotifyImage,
    },
    {
      title: "youtube",
      link: "https://music.youtube.com/channel/UC-6-bPOC7bNn4mLf9uwOuMQ",
      icon: youtubeImage,
    },
  ];
  return (
    <Box display={"flex"} justifyContent={"space-between"} mt={1} py={1} px={1}>
      {streamingPlatforms.map((sp, i) => (
        <Box display={"flex"} key={i}>
          <a href={sp.link} title={sp.title} target={"_blank"} rel="noreferrer">
            <img alt={sp.title} src={sp.icon} style={styles.icon} />
          </a>
        </Box>
      ))}
    </Box>
  );
}
