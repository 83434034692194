import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { createTheme, ThemeProvider, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import YouTubeIcon from "@mui/icons-material/YouTube";

import styles from "./../App.module.css";

const ZoharRiverTheme = createTheme({
  typography: {
    h1: {
      color: "white",
    },
    a: {
      color: "white",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      },
    },
  },
});
export default function Topbar() {
  const socialPlatforms = [
    {
      title: "facebook",
      link: "https://www.facebook.com/zohar.river",
      icon: FacebookIcon,
    },
    {
      title: "twitter",
      link: "https://twitter.com/zoharriver",
      icon: TwitterIcon,
    },
    {
      title: "instagram",
      link: "https://www.instagram.com/zohar.river",
      icon: InstagramIcon,
    },
    {
      title: "tiktok",
      link: "https://www.tiktok.com/@zohar.river",
      icon: MusicNoteIcon,
    },
    {
      title: "youtube",
      link: "https://www.youtube.com/channel/UC3gsVN_tmBgCPlIPo4lUCcQ",
      icon: YouTubeIcon,
    },
  ];

  return (
    <ThemeProvider theme={ZoharRiverTheme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="absolute">
          <Toolbar>
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              alignItems="center"
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box>
                <h1>Zohar River</h1>
              </Box>
              <Box>
                <Box display={"flex"} gap={2} width={"100%"}>
                  {socialPlatforms.map((sp, i) => (
                    <a
                      className={styles.link}
                      key={i}
                      href={sp.link}
                      title={sp.title}
                      aria-label={sp.title}
                      target="__blank"
                    >
                      <sp.icon />
                    </a>
                  ))}
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
